import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { navigate } from "gatsby"
import { FormattedMessage, FormattedDate } from "react-intl"
import { Chip, Tooltip } from "@material-ui/core"

const useStyles = makeStyles({
  card: {
    //maxWidth: 345,
  },
  media: {
    height: 140,
  },
  content: {
    //do nothing
  },
  button: {
    color: "#03A9F4",
  },
  chiparea: {
    paddingTop: 10,
  },
  chip: {
    marginRight: 3,
  },
  price: {},
  title: {
    height: "96px",
  }
})

const LectureCard = props => {
  const classes = useStyles()

  return (
    <Card className={classes.card} key={`card-${props.title}`}>
      <CardActionArea key={`card-action-area-${props.title}`}>
        <CardMedia
          className={classes.media}
          image={`https://source.unsplash.com/400x200/?${props.tags[props.tags.length - 1]}`}
          title={props.title}
          key={`card-media-${props.title}`}
        />
        <CardContent className={classes.content} key={`card-content-${props.title}`}>
          <Tooltip title={props.title} key={`card-tooltip-${props.title}`}>
            <Typography gutterBottom variant="h5" component="h2" key={`card-typography-${props.title}`} className={classes.title}>
              {props.title}
            </Typography>
          </Tooltip>
          <div className={classes.price} key={`card-price-div-${props.title}`}>
            {/* <FormattedMessage id="price" key={`card-price-${props.title}`}/>:{" "}
            <span style={{ textDecoration: "line-through" }}>
              {props.price}
            </span>{" "} */}
            {/* <span style={{ color: "#FF0000" }}>
              <FormattedMessage id="free" key={`card-free-${props.title}`}/>!
            </span> */}
          </div>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            noWrap
            key={`card-typography-date-${props.title}`}
          >
            <FormattedDate value={new Date(props.date)} key={`card-date-value-${props.title}`}/>
          </Typography>
          <div className={classes.chiparea}>
            {props.tags.map(tag => (
              <Chip label={<FormattedMessage id={`${tag}`}/>} className={classes.chip} key={`card-chip-${props.title}-${tag}`}></Chip>
            ))}
          </div>
        </CardContent>
      </CardActionArea>
      <CardActions key={`card-actions-${props.title}`}>
        <Button
          size="small"
          className={classes.button}
          onClick={() => navigate(props.slug)}
          key={`card-button-read-${props.title}`}
        >
          <FormattedMessage id="read" key={`card-button-read-value-${props.title}`}/>
        </Button>
      </CardActions>
    </Card>
  )
}

export default LectureCard
