import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import ListSubheader from "@material-ui/core/ListSubheader"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Divider, Paper } from "@material-ui/core"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import { FormattedMessage } from "react-intl"
const _ = require("lodash")

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: 10,
    paddingTop: 7,
    // display: "flex",
    // flexWrap: "wrap",
    // backgroundColor: "#F68940",
    // border: "solid 1px #000000"
  },
  listHeader: {
    backgroundColor: "#FFD342", //#FF9800
    fontSize: "17px",
    color: "#000000",
  },
  divider: {
    backgroundColor: "#FFFFFF",
  },
  listItem: {
    backgroundColor: "#ffd342",
  },
  listItemText: {
    fontSize: "14px",
    color: "#000000",
  },
  expandIcon: {
    color: "#000000"
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedText: {
    fontSize: "12.5px",
    color: "#000000",
  }
}))

const CategoryList = props => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        group(field: frontmatter___tags) {
          fieldValue
          edges {
            node {
              fields {
                langKey
              }
              frontmatter {
                category
              }
            }
          }
        }
      }
    }
  `)

  let categories = {}
  let initialOpen = {}
  data.allMarkdownRemark.group.map(tag => {
    tag.edges.map(edge => {
      if (edge.node.fields.langKey === props.langKey) {
        if (!categories[`${edge.node.frontmatter.category}`]) {
          categories[`${edge.node.frontmatter.category}`] = []
          initialOpen[`${edge.node.frontmatter.category}`] = true
          categories[`${edge.node.frontmatter.category}`].push(tag.fieldValue)
        } else if (
          categories[`${edge.node.frontmatter.category}`] &&
          categories[`${edge.node.frontmatter.category}`].indexOf(
            tag.fieldValue
          ) < 0
        ) {
          categories[`${edge.node.frontmatter.category}`].push(tag.fieldValue)
        }
      }
    })
  })
  const [open, setOpen] = React.useState(initialOpen)
  const [selected, setSelected] = React.useState(props.tag)

  const handleClick = category => () => {
    const newOpen = { ...open }
    newOpen[`${category}`] = !open[`${category}`]
    setOpen(newOpen)
  }

  return (
    <Paper>
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          className={classes.listHeader}
        >
          <FormattedMessage id="categories" />
        </ListSubheader>
      }
      className={classes.root}
    >
      {Object.keys(categories).map(category => (
        <div key={`category-${category}`}>
          <Divider variant="middle" className={classes.divider} />
          <ListItem
            button
            onClick={handleClick(category)}
            key={`key-category-${category}`}
            className={classes.listItem}      
          >
            <ListItemText
              primary={<FormattedMessage id={`${category}`} />}
              key={`key-category-text-${category}`}
              className={classes.listItemText}
            />
            {open[`${category}`] ? <ExpandLess className={classes.expandIcon}/> : <ExpandMore className={classes.expandIcon}/>}
          </ListItem>
          <Collapse
            in={open[`${category}`]}
            timeout="auto"
            unmountOnExit
            key={`key-collapse-${category}`}
          >
            <List component="div" disablePadding>
              {categories[`${category}`].map(tag => (
                <ListItem
                  button
                  className={classes.nested}
                  component={Link}
                  to={`/${props.langKey}/tags/${_.kebabCase(
                    tag.toLowerCase()
                  )}`}
                  key={`key-tag-${tag}`}
                  selected={tag === selected}
                >
                  <ArrowRightIcon />
                  <ListItemText primary={<FormattedMessage id={`${tag}`} />} key={`key-tag-text-${tag}`} className={classes.nestedText}/>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      ))}
    </List>
    </Paper>
  )
}

export default CategoryList
