module.exports = {
  selectLanguage: "choisissez votre langue",
  displayName: "Le français",
  categories: "Les catégories",
  read: "Lis",
  price: "Prix",
  lastUpdate: "Dernière mise à jour",
  free: "Maintenant disponible gratuitement",
  Software: "Logiciel",
  "Machine Learning": "Apprentissage automatique",
  Python: "Python",
  Azure: "Azure",
  "ARM Template": "ARM Template",
  Tags: "Mots clés",
  Home: "Page d'accueil",
  "API Management": "API Management",
  "Key Vault": "Key Vault",
  "Contact Form": "Formulaire de contact",
  "Cancel": "Annuler",
  "Submit": "Soumettre",
  "Contact Message": "Nous vous serions reconnaissants de nous faire part de vos commentaires sur notre site Web.",
  "Flutter": "Flutter"
}
