import { Grid } from "@material-ui/core"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import CardItem from "./carditem"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    // minWidth: "300",
    // minHeight: "400"
  },
}))

const CardList = props => {
  const classes = useStyles()

  return (
    <Grid container wrap="nowrap" className={classes.root} spacing={2}>
      {props.itemsrc.allMarkdownRemark.edges.map(({ node }) => (
        <Grid item zeroMinWidth xs={12} sm={12} md={6} lg={4} xl={4} key={`card-item-${node.frontmatter.title}`}>
          <CardItem
            className={classes.card}
            title={node.frontmatter.title}
            body={node.excerpt}
            slug={node.fields.slug}
            tags={node.frontmatter.tags}
            author={node.frontmatter.author}
            date={node.frontmatter.date}
            price={node.frontmatter.price}
          ></CardItem>
        </Grid>
      ))}
    </Grid>
  )
}

export default CardList
