import React from "react"
import CardList from "../components/cardlist"
import { Grid } from "@material-ui/core"
import CategoryList from "../components/categorylist"

const ListMainLayout = (props) => (

    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <CategoryList langKey={props.langKey} tag={props.tag} key={`category-list`}></CategoryList>
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
        <CardList itemsrc={props.data}></CardList>
      </Grid>
    </Grid>
)

export default ListMainLayout
